@font-face {
  font-family: "Earth Orbiter";
  src: url("../fonts/earthorbiterbold.ttf") format("truetype")
}
@font-face {
  font-family: "Nasalization";
  src: url("../fonts/nasalization-rg.otf") format("opentype")
}

.eva-gradient {
  background: linear-gradient(103deg, #7332F4, #AB34F2) !important
}
.gradient-button {
  padding: 15px 30px;
  border: none;
  border-radius: 31px;
  background: linear-gradient(103deg, #7332F4, #AB34F2);
  box-shadow: 0px 3px 6px #00000029;
  color: white;
  font-size: 16px;
  font-family: Earth Orbiter;
  text-transform: uppercase;
  text-decoration: none;
}
h1, h2, h3, h4, .text-ocean-orbiter {
  font-family: Earth Orbiter;
}
body {
  font-family: Nasalization;
}
